<template>
  <div>
    <div class="commonInputTopBox">
      <pageHeader :pagename="'合作方账户管理'" :total="total" />

      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="搜索">
          <el-input placeholder="用户名，手机号，邮箱" v-model="keyword"></el-input>
        </el-form-item>

        <el-form-item style="float: right">
          <el-button type="primary" @click="queryInput()">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="layoutTabaleBorder">
      <common-table
        :tableData="tableData"
        :tableLabel="tableLabel"
        :tableButton="tableButton"
        @edit="editFirstLevel"
      ></common-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 20, 40]"
        :page-size="pagesize"
        layout="total, sizes, prev, pager, next, jumper"
      >
      </el-pagination>
      <!-- <common-table></common-table> -->
    </div>
  </div>
</template>

<script>
import { partner_account_query, partner_account_delete } from "@/api/manage/business/partner_account/partner_account.js";
import CommonTable from "frontelementpackage/src/CommonTableButtonFixed.vue";
// import {garage_account_get } from "@/api/manage/business/garage/garage.js";
export default {
  name: "start",

  data() {
    return {
      query: {
        querypage: 1, //获取页码
        pagesize: 10, //每页返回数量
        keyword: "", //搜索 可搜邮箱 用户名 手机号
      },
      keyword: "",
      //分页数据
      total: 0,
      currentPage: 1, //初始页
      pagesize: 10, //    每页的数据
      dialogFormVisibleEdit: false,
      form: { group_info: "" },
      dialogFormVisibleAdd: false,
      formAdd: {},
      tableData: [],
      tableButton:{
        width:200,
        data: [
        {
          name: "账户详细",
          type: "primary",
          size: "small",
        },

        {
          name: "删除",
          type: "danger",
          size: "small",
        },
      ]
      },
      tableLabel: [
        {
          prop: "name",
          label: "账户名字",
          minWidth:"20%"
        },
        {
          prop: "email",
          label: "邮箱",
          minWidth:"20%"
        },
        {
          prop: "phone",
          label: "手机号",
          minWidth:"20%"
        },
        {
          prop: "garage_account_relevance_count",
          label: "车库关联数量",
          minWidth:"20%"
        },
        {
          prop: "sub_account_count",
          label: "子账户数量",
          minWidth:"20%"
        },
        {
          prop: "status",
          label: "状态",
          minWidth:"20%"
        },
      ],
    };
  },
  components: { CommonTable },
  created() {
    this.getTableInfo();
  },
  methods: {
    editFirstLevel(row, methods) {
      console.log(row, methods);
      if (methods == "删除") {
        console.log(row.id);
        this.delPartnerCount(row.id);
      }
      if (methods == "账户详细") {
        this.$router.push({
          name: "partner_Manage_Info",
          params: {
            id: row.id,
            name: row.name,
            email: row.email,
            phone: row.email,
            status: row.status,
          },
        });
      }
    },
    delPartnerCount(id) {
      let ids = id;
      this.$confirm("是否确定删除", "确认信息", {
        distinguishCancelAndClose: true,
        confirmButtonText: "删除",
        cancelButtonText: "放弃删除",
      })
        .then(() => {
          partner_account_delete({
            account_id: ids,
            is_delete: true,
          }).then((res) => {
            if (res.code == 200) {
              this.$message.success("成功删除");
              window.location.reload();
            } else {
              this.$message.info(res.msg);
            }
          });
        })
        .catch((action) => {
          this.$message({
            type: "info",
            message: action === "cancel" ? "放弃删除" : "停留在当前",
          });
        });
    },
    queryInput() {
      this.query = {
        querypage: this.currentPage,
        pagesize: this.pagesize,
        keyword: this.keyword,
      };
      partner_account_query(this.query).then((res) => {
        this.tableData = res.data.result;
      });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.query = {
        querypage: this.currentPage,
        pagesize: this.pagesize,
        keyword: this.keyword,
      };
      //  console.log("当前页面显示xxx条", this.currentPage,"当前界面是xxx页",this.currentPage);
      partner_account_query(this.query).then((res) => {
        this.tableData = res.data.result;
      });
    },
    handleSizeChange(val) {
      this.pagesize = val; //获取page-sizes里的每页显示几条数据的值，赋给我们自定义的每页显示数量的变量pageNum
      // console.log("当前页面显示xxx条", val,"当前界面是xxx页",this.currentPage);
      this.query = {
        querypage: this.currentPage,
        pagesize: this.pagesize,
        keyword: this.keyword,
      };
      partner_account_query(this.query).then((res) => {
        console.log(this.tableData, "new");
        this.tableData = res.data.result;
      });
    },

    createData() {
      // user_group_manage_create(this.formAdd).then((res) => {
      //   if (res.code == 200) {
      //     this.$message.success("成功添加");
      //     window.location.reload();
      //   } else {
      //     this.$message.info(res.msg);
      //   }
      // });
      // this.dialogFormVisibleEdit = false;
    },
    addFormButton() {
      this.dialogFormVisibleAdd = true;
    },
    updateData() {
      // user_group_manage_update(this.form).then((res) => {
      //   if (res.code == 200) {
      //     this.$message.success("成功修改");
      //     window.location.reload();
      //   } else {
      //     this.$message.info(res.msg);
      //   }
      // });
      // this.dialogFormVisibleEdit = false;
    },
    getTableInfo() {
      console.log(this.query);
      // partner_account_query
      partner_account_query(this.query).then((res) => {
        this.total = res.data.total;
        this.tableData = res.data.result;
      });
    },
  },
};
</script>

<style type="scss"></style>
